import { DropdownItem } from '../dropdown-item.type';
import { OptionsGroup } from '../options-group.model';

export class OptionItem {
  public readonly isGroup: boolean;
  public readonly groupLabel: string | any;
  public readonly children: OptionItem[];
  public readonly flatten: OptionItem[];

  public globalIndex = -1; // group or unknown item has globalIndex -1
  public source: DropdownItem | null = null;
  public element: HTMLElement | null = null;

  private _level = 0;
  private _visible = false;

  constructor(
    source: DropdownItem | OptionsGroup,
    public readonly index = 0,
    level = 0
  ) {
    this.isGroup = source instanceof OptionsGroup;
    this.children = this.isGroup
      ? (source as OptionsGroup).options.map((child, childIndex) => new OptionItem(child, childIndex, this.level + 1))
      : [];

    this.groupLabel = this.isGroup ? (source as OptionsGroup).label : '';
    this.source = this.isGroup ? null : source as DropdownItem;

    this.flatten = this.children.reduce((collected, child) => collected.concat(child.isGroup ? child.flatten : child), []);
    this.level = level;
  }

  public set level(value: number) {
    this._level = value;

    this.children.forEach((item) => {
      item.level = value + 1;
    });

    if (value === 0) {
      this.flatten.forEach((item, index) => {
        item.globalIndex = index;
      });
    }
  }

  public get level(): number {
    return this._level;
  }

  public set visible(value: boolean) {
    this._visible = value;
  }

  public get visible(): boolean {
    return this.isGroup ? this.children.findIndex((item) => item.visible) !== -1 : this._visible;
  }

  public getLabelText(): string {
    if (!this.element) {
      return '';
    }

    return this.isGroup
      ? this.element.firstChild.textContent // header only
      : this.element.textContent;
  }

  public getRaw(): DropdownItem | OptionsGroup {
    return this.isGroup
      ? new OptionsGroup(this.groupLabel, this.children.map((child) => child.getRaw()))
      : this.source;
  }

  public static fromArray(input: any[]): OptionItem {
    const array = Array.isArray(input) ? input : [];

    if (array.find((item) => item instanceof OptionItem)) {
      throw new Error('OptionItem can not appear in OptionItem.fromArray argument');
    }

    return new OptionItem(new OptionsGroup('root', array));
  }
}
