export const environment: any = {
  production: true,
  apiUrl: '//staging.progetsecure.com/api/secure',
  version: '189d130cce5181378f1e16f04749c3c9a0eab1c9',
  sentry: {
    dsn: 'https://c6bc99434c67361431eb25604b77b4dd@o4506381833601024.ingest.sentry.io/4506382059372544',
    replay: false,
  },
  firstDayOfWeek: 1,
};
