import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ObjectHelper, StringHelper } from '@proget-shared/helper';
import { OverlayPanelComponent } from '@proget-shared/ui/overlay-panel';
import { ScrollbarComponent } from '@proget-shared/ui/scrollbar';
import { ShortcutService } from '@proget-shared/ui/shortcut';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';

import { DropdownItem } from '../dropdown-item.type';
import { OptionItem } from '../options-group/option-item.model';
import { OptionsGroup } from '../options-group.model';

import { DropdownPanelConfiguration } from './dropdown-panel-configuration.type';

@Component({
  selector: 'app-dropdown-panel',
  templateUrl: './dropdown-panel.component.html',
  styleUrls: ['./dropdown-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  public readonly close = new EventEmitter<void>();
  @Output()
  public readonly selectedItemsChange = new EventEmitter<OptionItem[]>();
  public readonly form = new FormGroup({
    selection: new FormControl([]),
    filter: new FormControl(''),
  });
  public readonly activeIndex$: Observable<number | null>;
  public readonly update$: Observable<void>;

  protected root = OptionItem.fromArray([]);

  private readonly subscription = new Subscription();
  private readonly arrowNavigationSubject = new Subject<number>();
  private readonly activeIndexSubject = new Subject<number | null>();
  private readonly updateSubject = new Subject<void>();
  private readonly optionsSubject = new Subject<OptionItem>();
  private readonly configurationSubject = new Subject<DropdownPanelConfiguration>();

  @ViewChild('filterInput', { read: ElementRef })
  private filterInputElement: ElementRef;
  @ViewChild('scrollbar', { read: ElementRef })
  private scrollbarEl: ElementRef;
  @ViewChild('scrollbar')
  private scrollbarComponent: ScrollbarComponent;
  private hoverGroupIndex = -1;
  private _selectedItems = [];
  private _configuration: DropdownPanelConfiguration = {
    multiple: false,
    translate: true,
    autoSort: false,
    emptyMessage: '',
    emptyFilterMessage: '',
    showToggleAll: false,
    showToggleGroup: false,
    showHeader: true,
    filter: false,
    optionTemplate: undefined,
    groupLabelTemplate: undefined,
    valueKey: '',
    groupLabelEllipsis: true,
    optionLabelEllipsis: true,
    maxVisibleItems: 10,
  };

  constructor(
    private overlayPanel: OverlayPanelComponent,
    private shortcutService: ShortcutService,
    private cdr: ChangeDetectorRef
  ) {
    this.update$ = this.updateSubject.pipe(startWith(null as any));

    this.activeIndex$ = this.activeIndexSubject.pipe(
      map((index) => (index === -1 ? null : index)),
      startWith(null as number),
      distinctUntilChanged(),
      shareReplay(1)
    );

    const selection$ = this.form.get('selection').valueChanges.pipe(shareReplay(1));
    const configuration$ = this.configurationSubject.pipe(shareReplay(1));

    this.subscription.add(
      this.optionsSubject
        .pipe(
          switchMap((group) => combineLatest([
            of(group),
            configuration$.pipe(take(1)),
            selection$.pipe(take(1)),
          ]))
        )
        .subscribe(([group, configuration]) => {
          this.root = configuration.multiple ? this.moveSelectionToTop(group) : group;
          this.cdr.detectChanges();

          const formControl = this.form.get('filter');

          formControl.setValue('', { emitEvent: false });
          this.root.flatten.length
            ? formControl.enable({ emitEvent: false })
            : formControl.disable({ emitEvent: false });

          if ((this.form.value.filter || '').trim() === '') {
            this.root.flatten.forEach((item) => {
              item.visible = true;
            });
          }

          this.displayOptions();
        })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.activeIndex$.pipe(
        filter((index) => index !== null && index > -1 && index < this.root.flatten.length),
        map((index) => this.root.flatten[index].element.getBoundingClientRect())
      )
        .subscribe({
          next: (itemBounds) => {
            const rootGroupElement = this.scrollbarComponent.scrollbar.viewport.nativeElement;
            const rootGroupBounds = rootGroupElement.getBoundingClientRect();
            const rootGroupScrollTop = rootGroupElement.scrollTop;

            if (rootGroupBounds.bottom + 5 < itemBounds.bottom) {
              this.scrollbarComponent.scrollbar.scrollTo({
                top: rootGroupScrollTop + itemBounds.bottom - rootGroupBounds.bottom,
                duration: 0,
              });
            }

            if (rootGroupBounds.top - 5 > itemBounds.top) {
              this.scrollbarComponent.scrollbar.scrollTo({
                top: rootGroupScrollTop - rootGroupBounds.top + itemBounds.top,
                duration: 0,
              });
            }
          },
        })
    );

    this.subscription.add(
      this.form.get('filter').valueChanges.pipe(debounceTime(100))
        .subscribe({
          next: (value) => {
            this.displayOptions(value);
          },
        })
    );

    this.subscription.add(
      this.arrowNavigationSubject.pipe(
        withLatestFrom(this.activeIndex$)
      )
        .subscribe({
          next: ([direction, activeIndex]) => {
            let newIndex: number;
            const currentIndex = this.hoverGroupIndex === -1
              ? activeIndex ?? -1
              : direction > 0 ? this.hoverGroupIndex - 1 : this.hoverGroupIndex;

            if (currentIndex === -1) {
              newIndex = direction > 0
                ? this.getFirstVisibleIndex()
                : this.getLastVisibleIndex();
            } else {
              const step = direction > 0
                ? this.root.flatten.slice(currentIndex + 1).findIndex((item) => item.visible)
                : this.root.flatten.slice(0, currentIndex).reverse()
                  .findIndex((item) => item.visible);

              newIndex = step !== -1
                ? currentIndex + (1 + step) * direction
                : direction > 0
                  ? this.getFirstVisibleIndex()
                  : this.getLastVisibleIndex();
            }

            this.setActiveIndex(newIndex);
          },
        })
    );

    this.subscription.add(
      this.shortcutService.on(['Space', 'Enter'])
        .pipe(
          withLatestFrom(this.activeIndex$),
          filter(([_, index]) => index !== null && index > -1)
        )
        .subscribe({
          next: ([_, index]) => {
            this.configuration.multiple
              ? this.toggleItem(this.root.flatten[index])
              : this.selectItem(this.root.flatten[index]);
          },
        })
    );
  }

  ngAfterViewInit(): void {
    if (this.configuration.filter) {
      setTimeout(() => {
        // safari requires timeout to prevent scrolling
        this.filterInputElement.nativeElement.focus({ preventScroll: true });
      }, 0);
    }

    this.updatePanelHeight();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.activeIndexSubject.complete();
    this.arrowNavigationSubject.complete();
    this.updateSubject.complete();
    this.optionsSubject.complete();
    this.configurationSubject.complete();
  }

  @Input()
  public set selectedItems(items: OptionItem[]) {
    this._selectedItems = items;
    this.form.get('selection').setValue(items.map((item) => item.source));
  }

  public get selectedItems(): OptionItem[] {
    return this._selectedItems;
  }

  @Input()
  public set optionsGroup(group: OptionItem) {
    this.optionsSubject.next(group);
  }

  @Input()
  public set configuration(configuration: Partial<DropdownPanelConfiguration>) {
    let configurationChanged = false;

    for (const key in configuration) {
      if (configuration.hasOwnProperty(key) && this.configuration.hasOwnProperty(key)) {
        if (this._configuration[key] === configuration[key]) {
          continue;
        }

        configurationChanged = true;
        this._configuration[key] = configuration[key];
      }
    }

    this.configurationSubject.next(this._configuration);

    if (configurationChanged) {
      this.updateSubject.next();
    }
  }

  public get configuration(): DropdownPanelConfiguration {
    return this._configuration;
  }

  public get emptyOptionsMessage(): string {
    if (this.hasVisibleOptions) {
      return '';
    }

    return this.root.flatten.length
      ? this.configuration.emptyFilterMessage || ''
      : this.configuration.emptyMessage || '';
  }

  public get hasVisibleOptions(): boolean {
    return this.root.flatten.findIndex((item) => item.visible) !== -1;
  }

  public get headerVisibility(): boolean {
    if (!this.configuration.showHeader) {
      return false;
    }

    const showToggleAll = this.configuration.multiple && this.configuration.showToggleAll;

    return showToggleAll || this.configuration.filter;
  }

  @HostBinding('class.fixed-width')
  public get hasFixedWidth(): boolean {
    return this.overlayPanel.matchWidth;
  }

  public isSelected(item: OptionItem): boolean {
    return item.isGroup
      ? false
      : this.form.value.selection.indexOf(item.source) !== -1;
  }

  public selectItem(item: OptionItem): void {
    this.emitSelectedItems([item]);
    this.close.emit();
  }

  public toggleItem(itemOrItems: OptionItem | OptionItem[]): void {
    const itemsArray = Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems];

    this.emitSelectedItems(itemsArray.reduce(
      (result, item) => {
        const itemStringValue = this.stringifyValue(item.source);

        return this.isSelected(item)
          // remove from selection
          ? result
            .filter((selectedItem) => this.stringifyValue(selectedItem.source) !== itemStringValue)
          // add to selection
          : result
            .concat(this.root.flatten
              .filter((optionItem) => result.indexOf(optionItem) === -1 &&
                this.stringifyValue(optionItem.source) === itemStringValue)
            );
      },
      this.selectedItems
    ));
  }

  @HostListener('document:keydown.arrowDown', ['$event', '1'])
  @HostListener('document:keydown.arrowUp', ['$event', '-1'])
  public keyNavigation(event: KeyboardEvent, direction: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.arrowNavigationSubject.next(direction);
  }

  public setActiveIndex(index: number, hoverGroup = false): void {
    this.hoverGroupIndex = -1;

    if (hoverGroup) {
      this.hoverGroupIndex = index;
    }

    this.activeIndexSubject.next(hoverGroup ? null : index);
  }

  private emitSelectedItems(items: OptionItem[]): void {
    this.selectedItemsChange.emit(items);
  }

  private displayOptions(queryString = ''): void {
    const queries = queryString
      .trim()
      .split(' ')
      .filter((item, index, all) => item !== '' && all.indexOf(item) === index)
      .map((query) => new RegExp(StringHelper.escapeRegExp(query), 'i'));

    this.root.flatten.forEach((item) => {
      item.visible = queries.reduce((result, query) => query.test(item.getLabelText()) && result, true);
    });

    this.updateSubject.next();
    this.setActiveIndex(-1);

    // add timeout to wait previous element to be removed from DOM
    // container.clear executes after creating new elements
    // so there are both previous and new elements in DOM at the same time
    setTimeout(() => {
      this.updatePanelHeight();
    });
  }

  private getItemValue(item: DropdownItem): any {
    if (this.configuration.valueKey) {
      return ObjectHelper.getValue(item, this.configuration.valueKey, null);
    }

    return item.value === undefined ? item : item.value;
  }

  private stringifyValue(item: DropdownItem): string {
    return JSON.stringify(this.getItemValue(item));
  }

  private getFirstVisibleIndex(): number {
    return this.root.flatten.findIndex((item) => item.visible);
  }

  private getLastVisibleIndex(): number {
    return this.root.flatten.length - this.root.flatten.slice().reverse()
      .findIndex((item) => item.visible) - 1;
  }

  private countVisibleItems(option: OptionItem): number {
    let count = 0;

    if (option.visible && (option.isGroup && option.groupLabel || !option.isGroup)) {
      count++;
    }

    if (option.children) {
      for (const child of option.children) {
        count += this.countVisibleItems(child);
      }
    }

    return count;
  }

  private updatePanelHeight(): void {
    const visibleItemsCount = this.countVisibleItems(this.root);
    const visibleItemsHeight = this.scrollbarComponent.scrollbar.viewport.nativeElement.firstElementChild.clientHeight;
    const itemHeight = visibleItemsCount ? visibleItemsHeight / visibleItemsCount : 0;
    const itemsCount = Math.min(visibleItemsCount, this.configuration.maxVisibleItems);

    this.scrollbarEl.nativeElement.firstElementChild.style.height = `${itemsCount * itemHeight}px`;
  }

  private moveSelectionToTop(group: OptionItem): OptionItem {
    // use form value because isSelected method also uses it
    if (!this.form.value.selection.length || !group.isGroup) {
      return group;
    }

    const selectedOptions = new OptionsGroup(
      {
        label: 'proget_shared.dropdown.selected_items_group',
        translate: true,
      },
      group.flatten.filter((item) => this.isSelected(item)).map((item) => item.getRaw())
    );

    const availableOptions = this.removeSelection(group.children);

    return OptionItem.fromArray([selectedOptions, ...availableOptions]);
  }

  private removeSelection(items: OptionItem[]): (DropdownItem | OptionsGroup)[] {
    return items
      .map((item) => {
        if (!item.isGroup) {
          return this.isSelected(item) ? null : item.getRaw();
        }

        const filteredChildren = this.removeSelection(item.children);

        return filteredChildren.length ? new OptionsGroup(item.groupLabel, filteredChildren) : null;
      })
      .filter((item) => item !== null);
  }
}
